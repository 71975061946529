import React, { useEffect, useState } from 'react'
import logo from '../../../assets/images/header-logo.svg'
import bars from '../../../assets/images/white-bars.svg'
import cross from '../../../assets/images/cross-circle.svg'
import whtsapp from '../../../assets/images/whtsap-green.svg'
import { Link, useNavigate } from 'react-router-dom'
import baseUrl from '../../../utils/utils'
import GetInTouchModal from '../../base-components/Getin_Touch_form/GetInTouchModal '

function Header({dialing_code}) {
    const [active , setActive] = useState(false);
  const [data, setData] = useState(null);
  const modalid = "myModalno1";

  const handleLinkClick = (path) => {
    return (e) => {
      e.preventDefault();
      window.location.href = path;
    };
  };

     useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`https://uae-offplan.com/admin.uae-offplan/homepage_api_url/${baseUrl}?X-API-KEY=3064c564d11154da943a1fd968822b6c`);
        const jsonData = await response.json();
        setData(jsonData);
        localStorage.setItem("meta-Tags", JSON.stringify(jsonData?.homepage_info))
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);
  

  const homepage_data = data?.homepage_info
  console.log(homepage_data?.community_name)

    const handleSideBar =()=>{
        setActive(!active)
    }

  const navigate = useNavigate();

  return (
    <>
    <div className='main-flex' >
    <div className='header-main'>
        <div className='header-logo'>
           <Link to="/" > <img src={homepage_data?.logo_image} /></Link>
        </div>
        <div className='header-items'>
        <ul>
        <li>
          <Link to="/" className='nav-item-link' onClick={handleLinkClick('/')}>Home</Link>
        </li>
        <li>
          <Link to="/projects" className='nav-item-link' onClick={handleLinkClick('/projects')}>New Projects</Link>
        </li>
        <li>
          <Link to="/properties-buy-and-rent" className='nav-item-link' onClick={handleLinkClick('/properties-buy-and-rent')}>Buy & Rent Properties</Link>
        </li>
      </ul>
        </div>

    </div>
    <div className="right-header">    
    <div className='button-header'>
      <button className='button-header-button' data-bs-toggle="modal" data-bs-target={`#${modalid}`}>
        Need Prompt Consultation?
      </button>
       <GetInTouchModal
                modalTitle="Need Prompt Consultation?"
                dialing_code={dialing_code}
                modalid={modalid}
                community_name={homepage_data?.community_name}
                FormName={"Need Prompt Consultation?"}
              />
    </div>
    <Link to={`https://api.whatsapp.com/send/?phone=971524474422&text=Hi+There%2C+I%27m+interested+in+${homepage_data?.community_name}.Kindly+send+me+more+information.+Thank+you%21+${baseUrl}&type=phone_number&app_absent=0`}>
        <div className='header-contact-info'>
                <div className='header-whtsapp-blue'>
                    <img src={whtsapp} />
                </div>
                <div className='header-info'>
                    <p>24 Hour Helpline</p>
                    <h3>+971  52 44722</h3>
                </div>
        </div>
    </Link>
        </div>
        <div className='bars'>
            <img src={bars} onClick={handleSideBar}/>
        </div>
    </div>



    <div className={`mobile-sidebar ${active ? "open" : "close"} `}>
        <div className='sidebar-top'>
            {/* <div className='sidebar-logo'>
                 <img src={homepage_data?.logo_image} />
            </div> */}
            <div className='sidebar-cross'>
            <img src={cross} onClick={handleSideBar} />
            </div>
        </div>
        <div className='sidebar-items'>
              <ul>
                <Link to="/"><li>Home</li></Link>
                 <Link to="/projects"><li>New Projects</li></Link>
                 <Link to="/properties-buy-and-rent"><li>Buy & Rent Properties</li></Link>
            </ul>
        </div>
    </div>
    </>
  )
}

export default Header
import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "./axiosInstance"; // Adjust the import path as needed
import baseUrl from "../../../utils/utils";
import { PhoneInput } from "react-international-phone";
import 'react-international-phone/style.css';

export default function MainForm({
  dialing_code = [],
  community_name,
  developer_name,
  unit_type,
  bedroom,
  size,
  price,
  FormName,
  modalTitle,
  project_name,
  page_url
}) {
  const [full_name, setName] = useState("");
  const [email_address, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [currentUrl, setCurrentUrl] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    setCurrentUrl(window.location.href);
  }, []);

  // const handlePhoneNumberChange = (e) => {
  //   setPhone(e.target.value);
  // };

  const urlToShow = page_url ? page_url : currentUrl;

const handleSubmit = async (e) => {
  e.preventDefault();

//   // Check if any of the fields are empty
  if (!full_name || !email_address || phone.length < 6 ) {
      setErrorMessage("Please fill out all fields.");
      setTimeout(() => {
        setErrorMessage("");
      }, 5000);
      return;
    }
  const New_lead = "New Lead";
  const data = {
    full_name,
    email_address,
    phone_number: phone,
    page_url: urlToShow,
    source: baseUrl,
    formname: FormName,
    looking_for: modalTitle,
    stage: New_lead,
    project_name: project_name,
    community_name: community_name,
    developer_name: developer_name,
    unit_type: unit_type,
    bedroom: bedroom,
    size: size,
    price: price,
  };

  try {
    const response = await axiosInstance.post(
      `https://${baseUrl}/types_web_pages_lead`,
      data
    );

    console.log("Success:", response.data);
    setName("");
    setEmail("");
    setPhone("");
    setErrorMessage("");
    
    // Navigate to the thank-you page
    navigate('/thank-you');
    window.location.reload();
  } catch (error) {
    console.error("Error:", error);
    if (error.response) {
      setErrorMessage(`Error: ${error.response.data.message}`);
    } else if (error.request) {
      setErrorMessage("No response from the server. Please try again later.");
    } else {
      setErrorMessage(
        "An error occurred while submitting the form. Please try again."
      );
    }
  }
};




  return (
    <>
      <form>
        <div className="mb-3">
          <label htmlFor="name" className="form-label">
          <b>Full Name*</b>
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Full Name"
            id="name"
            name="name"
            value={full_name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="email" className="form-label">
            <b>Email Address*</b>
          </label>
          <input
            type="email"
            className="form-control"
            placeholder="Enter Email Address"
            id="email"
            name="email"
            value={email_address}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        {/* <div className="mb-3">
          <label htmlFor="phone" className="form-label">
            PHONE NUMBER*
          </label>
          <div className="input-group">
            <input
              type="tel"
              className="form-control"
              placeholder="Enter Phone number"
              id="phone"
              name="phone"
              value={phone}
              maxLength={15} // Max length to restrict phone number input
              onChange={handlePhoneNumberChange}
              required
            />  
          </div>
        </div> */}
        <div className="mb-3">
          <label htmlFor="phone" className="form-label">
            <b>Phone Numberasas*</b>
          </label>
            <PhoneInput
              defaultCountry="pk"
              value={phone}
              onChange={(phone) => setPhone(phone)}
            />
        </div>
        {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
        <div className="modal-footer">
          <button type="button" onClick={handleSubmit} className="btn btn-primary w-100 ms-4 me-4">
            Submit
          </button>
        </div>
      </form>
    </>
  );
}

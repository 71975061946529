import React, { useState } from "react";
import MainForm from "./MainForm";


const GetInTouchModal = ({ modalTitle, dialing_code = [],community_name,modalid ,developer_name,unit_type,bedroom,size,price,project_name,page_url, FormName,subtitle}) => {
  return (
    <div
      className="modal fade"
      id={modalid}
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <div>
            <h5 className="modal-title" id="exampleModalLabel">
              {modalTitle}
            </h5>
          {subtitle == "none" ? "" : (<p className="modal-para">Our expert agent will contact</p>)}
            </div>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <MainForm
            dialing_code={dialing_code} 
            community_name={community_name} 
            modalTitle={modalTitle} 
            project_name={project_name}
            developer_name={developer_name}
            unit_type={unit_type}
            bedroom={bedroom}
            size={size}
            price={price}
            FormName={FormName}
            page_url={page_url}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetInTouchModal;

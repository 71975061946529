import axios from "axios";
import baseUrl from "../../../utils/utils";
const axiosInstance = axios.create({
  
  
  baseURL: `http://${baseUrl}`, // Replace with your actual base URL
  headers: {
    "X-API-KEY": "3064c564d11154da943a1fd968822b6c",
    "Content-Type": "application/json",
  },
  withCredentials: true, // Enable credentials
});

export default axiosInstance;

import React from 'react';
import { useEffect, useState } from 'react';
import { RouterProvider } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import "./assets/styles/style.css"
import "./assets/styles/responsive.css"
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import 'intl-tel-input/build/css/intlTelInput.css';
import router from "./createBorwserRouter";
import baseUrl from './utils/utils';


function App() {
    const [data, setData] = useState(null);

     useEffect(() => {
    const fetchData = async () => {
      try {
        // const response = await fetch('https://uae-offplan.com/admin.uae-offplan/homepage_api_url/arabianranches.community?X-API-KEY=3064c564d11154da943a1fd968822b6c');
        const response = await fetch(`https://uae-offplan.com/admin.uae-offplan/homepage_api_url/${baseUrl}?X-API-KEY=3064c564d11154da943a1fd968822b6c`);
        const jsonData = await response.json();
        setData(jsonData);
        localStorage.setItem("meta-Tags", JSON.stringify(jsonData?.homepage_info))
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);
   const homepage_data = data?.homepage_info
  const favicon = homepage_data?.favicon
   React.useEffect(() => {
    const link = document.querySelector("link[rel~='icon']");

    // Create a new link element if it doesn't exist
    if (!link) {
      const newLink = document.createElement('link');
      newLink.rel = 'icon';
      newLink.href = favicon;
      document.head.appendChild(newLink);
    } else {
      link.href = favicon;
    }
  }, [favicon]);
  return (
    <div>
      
      <RouterProvider router={router} />
    </div>
  );
}

export default App;

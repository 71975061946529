import React, { Suspense, lazy } from 'react';
import { createBrowserRouter } from 'react-router-dom';
import ThankYou from './pages/ThankYou';

// Lazy load components
const Index = lazy(() => import('./pages/home/file/Index'));
const Listing = lazy(() => import('./pages/listing-detail/file/Listing-detail'));
const ListingRent = lazy(() => import('./pages/listing-rent/file/ListingRent'));
const ProjectDetail = lazy(() => import('./pages/project-detail/file/Project-detail'));
const Projects = lazy(() => import('./pages/projects/file/Projects'));

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <Suspense>
        <Index />
      </Suspense>
    ),
  },
  {
    path: 'properties-for-sale/:secondParam',
    element: (
      <Suspense>
        <Listing />
      </Suspense>
    ),
  },
  {
    path: 'properties-buy-and-rent',
    element: (
      <Suspense>
        <ListingRent />
      </Suspense>
    ),
  },
  {
    path: 'projects/:secondParam',
    element: (
      <Suspense>
        <ProjectDetail />
      </Suspense>
    ),
  },
  {
    path: 'projects',
    element: (
      <Suspense>
        <Projects />
      </Suspense>
    ),
  },
  {
    path: 'thank-you',
    element: (
      <Suspense>
        <ThankYou />
      </Suspense>
    ),
  },
]);

export default router;

import React from 'react'
import Header from '../componnents/layout-components/Header/Header'

function ThankYou() {
  return (
    <div>
        <Header/>
        <div className='thankyou-main'>
          
     
        <h2>Thank you for getting in touch!</h2>
            <p>We appreciate you contacting us. One of our colleagues will get back in touch with you soon! Have a great day!
            <p>We're here to help</p>
        Seven days a week, 365 days of the year, our friendly, expert team is just a phone call or email away.</p>
           </div>
        </div>
  )
}

export default ThankYou